body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#282c34;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

content {
  width: 95%;
  align-content: center
}

.App {
  text-align: center;
}
.a{
  color:#ffffff
}
.App-logo {
  align-self: center;
  float: top;
  position:absolute;
}
.lilTable{
  width:50%;
}

.lilerTable{
  width:40%;
  
}
.inline{
  display: inline-block;
}
.minTable{
  position: static;
  margin: 0px;
  padding: 0px;
  width:43px;
  -webkit-column-width: 43px;
     -moz-column-width: 43px;
          column-width: 43px;
  text-align:left;
  align-content:left;
  -webkit-transform: translate(-10px, 0px);
  transform: translate(-10px, 0px);
}
.cell{
  margin-left: 0px;
  padding-left: 0px;
  border-spacing: 0px
}
.profpic{
  margin: 0px;
  border-width: 1px;
  border-color: #000000;
  box-shadow: #000000
}
.banner{
  background-color:#30333a
}
.bigTable{
  width:300px;
  margin: 0px;
  padding: 0px;
  -webkit-padding-after: 0px;
          padding-block-end: 0px;
  position:relative;
}
.alignleft{
  text-align: left;
}
.alignright{
  text-align: right;
 
}
.App-header {
  float:top;
  width:60%;
  position:absolute;
  margin-top: 0;
  background-color: #282c34;
  min-height: 100vh;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 100px;
}
.Nav{
  border: 1px solid #000000;
  color:#00ffac;
  margin-top:150px;
  border-width:1px;
  border-radius: 3px;
  border-color:#000;
  height: 500px;
  float:left;
  width:15%;
  text-align:center;
}

.Navright{
  border: 1px solid #000000;
  color:#00ffac;
  border-width:1px;
  border-radius: 3px;
  border-color:#000;
  height: 500px;
  right:0px;
  float: left;
  width:15%;
  margin-top:150px;
  text-align:center;
}
.Main {
  margin-top:150px;
  float:left;
  width:60%;
  height:500px;
}
.App-link {
  color: #61dafb;
}

.thumb{
  padding: 5px;
  border: solid;  
  border-width: 2px;
  border-color:#000000;
}

.mainthumb{
  padding: 8px;
  border: solid;
  border-width: 2px;
  border-color:#a7a7a7;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.swingintopfwd {
	-webkit-animation: swing-in-top-fwd 0.75s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-top-fwd 0.75s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2019-1-10 22:45:57
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}

.link{
  text-decoration: underline;
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}

/* Bootstrap */

@font-face {
    font-family: 'Icons';
    src: url(/static/media/open-iconic.47151e87.eot);
    src: url(/static/media/open-iconic.47151e87.eot?#iconic-sm) format('embedded-opentype'), url(/static/media/open-iconic.3cf97837.woff) format('woff'), url(/static/media/open-iconic.93cc7aa6.ttf) format('truetype'), url(/static/media/open-iconic.527eff64.otf) format('opentype'), url(/static/media/open-iconic.eca2c267.svg#iconic-sm) format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  .oi {
    position: relative;
    top: 1px;
    display: inline-block;
    speak:none;
    font-family: 'Icons';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .oi:empty:before {
    width: 1em;
    text-align: center;
    box-sizing: content-box;
  }
  
  .oi.oi-align-center:before {
    text-align: center;
  }
  
  .oi.oi-align-left:before {
    text-align: left;
  }
  
  .oi.oi-align-right:before {
    text-align: right;
  }
  
  
  .oi.oi-flip-horizontal:before {
    -webkit-transform: scale(-1, 1);
    transform: scale(-1, 1);
  }
  
  .oi.oi-flip-vertical:before {
    -webkit-transform: scale(1, -1);
    transform: scale(1, -1);
  }
  
  .oi.oi-flip-horizontal-vertical:before {
    -webkit-transform: scale(-1, -1);
    transform: scale(-1, -1);
  }
  
  
  .oi-account-login:before {
    content:'\E000';
  }
  
  .oi-account-logout:before {
    content:'\E001';
  }
  
  .oi-action-redo:before {
    content:'\E002';
  }
  
  .oi-action-undo:before {
    content:'\E003';
  }
  
  .oi-align-center:before {
    content:'\E004';
  }
  
  .oi-align-left:before {
    content:'\E005';
  }
  
  .oi-align-right:before {
    content:'\E006';
  }
  
  .oi-aperture:before {
    content:'\E007';
  }
  
  .oi-arrow-bottom:before {
    content:'\E008';
  }
  
  .oi-arrow-circle-bottom:before {
    content:'\E009';
  }
  
  .oi-arrow-circle-left:before {
    content:'\E00A';
  }
  
  .oi-arrow-circle-right:before {
    content:'\E00B';
  }
  
  .oi-arrow-circle-top:before {
    content:'\E00C';
  }
  
  .oi-arrow-left:before {
    content:'\E00D';
  }
  
  .oi-arrow-right:before {
    content:'\E00E';
  }
  
  .oi-arrow-thick-bottom:before {
    content:'\E00F';
  }
  
  .oi-arrow-thick-left:before {
    content:'\E010';
  }
  
  .oi-arrow-thick-right:before {
    content:'\E011';
  }
  
  .oi-arrow-thick-top:before {
    content:'\E012';
  }
  
  .oi-arrow-top:before {
    content:'\E013';
  }
  
  .oi-audio-spectrum:before {
    content:'\E014';
  }
  
  .oi-audio:before {
    content:'\E015';
  }
  
  .oi-badge:before {
    content:'\E016';
  }
  
  .oi-ban:before {
    content:'\E017';
  }
  
  .oi-bar-chart:before {
    content:'\E018';
  }
  
  .oi-basket:before {
    content:'\E019';
  }
  
  .oi-battery-empty:before {
    content:'\E01A';
  }
  
  .oi-battery-full:before {
    content:'\E01B';
  }
  
  .oi-beaker:before {
    content:'\E01C';
  }
  
  .oi-bell:before {
    content:'\E01D';
  }
  
  .oi-bluetooth:before {
    content:'\E01E';
  }
  
  .oi-bold:before {
    content:'\E01F';
  }
  
  .oi-bolt:before {
    content:'\E020';
  }
  
  .oi-book:before {
    content:'\E021';
  }
  
  .oi-bookmark:before {
    content:'\E022';
  }
  
  .oi-box:before {
    content:'\E023';
  }
  
  .oi-briefcase:before {
    content:'\E024';
  }
  
  .oi-british-pound:before {
    content:'\E025';
  }
  
  .oi-browser:before {
    content:'\E026';
  }
  
  .oi-brush:before {
    content:'\E027';
  }
  
  .oi-bug:before {
    content:'\E028';
  }
  
  .oi-bullhorn:before {
    content:'\E029';
  }
  
  .oi-calculator:before {
    content:'\E02A';
  }
  
  .oi-calendar:before {
    content:'\E02B';
  }
  
  .oi-camera-slr:before {
    content:'\E02C';
  }
  
  .oi-caret-bottom:before {
    content:'\E02D';
  }
  
  .oi-caret-left:before {
    content:'\E02E';
  }
  
  .oi-caret-right:before {
    content:'\E02F';
  }
  
  .oi-caret-top:before {
    content:'\E030';
  }
  
  .oi-cart:before {
    content:'\E031';
  }
  
  .oi-chat:before {
    content:'\E032';
  }
  
  .oi-check:before {
    content:'\E033';
  }
  
  .oi-chevron-bottom:before {
    content:'\E034';
  }
  
  .oi-chevron-left:before {
    content:'\E035';
  }
  
  .oi-chevron-right:before {
    content:'\E036';
  }
  
  .oi-chevron-top:before {
    content:'\E037';
  }
  
  .oi-circle-check:before {
    content:'\E038';
  }
  
  .oi-circle-x:before {
    content:'\E039';
  }
  
  .oi-clipboard:before {
    content:'\E03A';
  }
  
  .oi-clock:before {
    content:'\E03B';
  }
  
  .oi-cloud-download:before {
    content:'\E03C';
  }
  
  .oi-cloud-upload:before {
    content:'\E03D';
  }
  
  .oi-cloud:before {
    content:'\E03E';
  }
  
  .oi-cloudy:before {
    content:'\E03F';
  }
  
  .oi-code:before {
    content:'\E040';
  }
  
  .oi-cog:before {
    content:'\E041';
  }
  
  .oi-collapse-down:before {
    content:'\E042';
  }
  
  .oi-collapse-left:before {
    content:'\E043';
  }
  
  .oi-collapse-right:before {
    content:'\E044';
  }
  
  .oi-collapse-up:before {
    content:'\E045';
  }
  
  .oi-command:before {
    content:'\E046';
  }
  
  .oi-comment-square:before {
    content:'\E047';
  }
  
  .oi-compass:before {
    content:'\E048';
  }
  
  .oi-contrast:before {
    content:'\E049';
  }
  
  .oi-copywriting:before {
    content:'\E04A';
  }
  
  .oi-credit-card:before {
    content:'\E04B';
  }
  
  .oi-crop:before {
    content:'\E04C';
  }
  
  .oi-dashboard:before {
    content:'\E04D';
  }
  
  .oi-data-transfer-download:before {
    content:'\E04E';
  }
  
  .oi-data-transfer-upload:before {
    content:'\E04F';
  }
  
  .oi-delete:before {
    content:'\E050';
  }
  
  .oi-dial:before {
    content:'\E051';
  }
  
  .oi-document:before {
    content:'\E052';
  }
  
  .oi-dollar:before {
    content:'\E053';
  }
  
  .oi-double-quote-sans-left:before {
    content:'\E054';
  }
  
  .oi-double-quote-sans-right:before {
    content:'\E055';
  }
  
  .oi-double-quote-serif-left:before {
    content:'\E056';
  }
  
  .oi-double-quote-serif-right:before {
    content:'\E057';
  }
  
  .oi-droplet:before {
    content:'\E058';
  }
  
  .oi-eject:before {
    content:'\E059';
  }
  
  .oi-elevator:before {
    content:'\E05A';
  }
  
  .oi-ellipses:before {
    content:'\E05B';
  }
  
  .oi-envelope-closed:before {
    content:'\E05C';
  }
  
  .oi-envelope-open:before {
    content:'\E05D';
  }
  
  .oi-euro:before {
    content:'\E05E';
  }
  
  .oi-excerpt:before {
    content:'\E05F';
  }
  
  .oi-expand-down:before {
    content:'\E060';
  }
  
  .oi-expand-left:before {
    content:'\E061';
  }
  
  .oi-expand-right:before {
    content:'\E062';
  }
  
  .oi-expand-up:before {
    content:'\E063';
  }
  
  .oi-external-link:before {
    content:'\E064';
  }
  
  .oi-eye:before {
    content:'\E065';
  }
  
  .oi-eyedropper:before {
    content:'\E066';
  }
  
  .oi-file:before {
    content:'\E067';
  }
  
  .oi-fire:before {
    content:'\E068';
  }
  
  .oi-flag:before {
    content:'\E069';
  }
  
  .oi-flash:before {
    content:'\E06A';
  }
  
  .oi-folder:before {
    content:'\E06B';
  }
  
  .oi-fork:before {
    content:'\E06C';
  }
  
  .oi-fullscreen-enter:before {
    content:'\E06D';
  }
  
  .oi-fullscreen-exit:before {
    content:'\E06E';
  }
  
  .oi-globe:before {
    content:'\E06F';
  }
  
  .oi-graph:before {
    content:'\E070';
  }
  
  .oi-grid-four-up:before {
    content:'\E071';
  }
  
  .oi-grid-three-up:before {
    content:'\E072';
  }
  
  .oi-grid-two-up:before {
    content:'\E073';
  }
  
  .oi-hard-drive:before {
    content:'\E074';
  }
  
  .oi-header:before {
    content:'\E075';
  }
  
  .oi-headphones:before {
    content:'\E076';
  }
  
  .oi-heart:before {
    content:'\E077';
  }
  
  .oi-home:before {
    content:'\E078';
  }
  
  .oi-image:before {
    content:'\E079';
  }
  
  .oi-inbox:before {
    content:'\E07A';
  }
  
  .oi-infinity:before {
    content:'\E07B';
  }
  
  .oi-info:before {
    content:'\E07C';
  }
  
  .oi-italic:before {
    content:'\E07D';
  }
  
  .oi-justify-center:before {
    content:'\E07E';
  }
  
  .oi-justify-left:before {
    content:'\E07F';
  }
  
  .oi-justify-right:before {
    content:'\E080';
  }
  
  .oi-key:before {
    content:'\E081';
  }
  
  .oi-laptop:before {
    content:'\E082';
  }
  
  .oi-layers:before {
    content:'\E083';
  }
  
  .oi-lightbulb:before {
    content:'\E084';
  }
  
  .oi-link-broken:before {
    content:'\E085';
  }
  
  .oi-link-intact:before {
    content:'\E086';
  }
  
  .oi-list-rich:before {
    content:'\E087';
  }
  
  .oi-list:before {
    content:'\E088';
  }
  
  .oi-location:before {
    content:'\E089';
  }
  
  .oi-lock-locked:before {
    content:'\E08A';
  }
  
  .oi-lock-unlocked:before {
    content:'\E08B';
  }
  
  .oi-loop-circular:before {
    content:'\E08C';
  }
  
  .oi-loop-square:before {
    content:'\E08D';
  }
  
  .oi-loop:before {
    content:'\E08E';
  }
  
  .oi-magnifying-glass:before {
    content:'\E08F';
  }
  
  .oi-map-marker:before {
    content:'\E090';
  }
  
  .oi-map:before {
    content:'\E091';
  }
  
  .oi-media-pause:before {
    content:'\E092';
  }
  
  .oi-media-play:before {
    content:'\E093';
  }
  
  .oi-media-record:before {
    content:'\E094';
  }
  
  .oi-media-skip-backward:before {
    content:'\E095';
  }
  
  .oi-media-skip-forward:before {
    content:'\E096';
  }
  
  .oi-media-step-backward:before {
    content:'\E097';
  }
  
  .oi-media-step-forward:before {
    content:'\E098';
  }
  
  .oi-media-stop:before {
    content:'\E099';
  }
  
  .oi-medical-cross:before {
    content:'\E09A';
  }
  
  .oi-menu:before {
    content:'\E09B';
  }
  
  .oi-microphone:before {
    content:'\E09C';
  }
  
  .oi-minus:before {
    content:'\E09D';
  }
  
  .oi-monitor:before {
    content:'\E09E';
  }
  
  .oi-moon:before {
    content:'\E09F';
  }
  
  .oi-move:before {
    content:'\E0A0';
  }
  
  .oi-musical-note:before {
    content:'\E0A1';
  }
  
  .oi-paperclip:before {
    content:'\E0A2';
  }
  
  .oi-pencil:before {
    content:'\E0A3';
  }
  
  .oi-people:before {
    content:'\E0A4';
  }
  
  .oi-person:before {
    content:'\E0A5';
  }
  
  .oi-phone:before {
    content:'\E0A6';
  }
  
  .oi-pie-chart:before {
    content:'\E0A7';
  }
  
  .oi-pin:before {
    content:'\E0A8';
  }
  
  .oi-play-circle:before {
    content:'\E0A9';
  }
  
  .oi-plus:before {
    content:'\E0AA';
  }
  
  .oi-power-standby:before {
    content:'\E0AB';
  }
  
  .oi-print:before {
    content:'\E0AC';
  }
  
  .oi-project:before {
    content:'\E0AD';
  }
  
  .oi-pulse:before {
    content:'\E0AE';
  }
  
  .oi-puzzle-piece:before {
    content:'\E0AF';
  }
  
  .oi-question-mark:before {
    content:'\E0B0';
  }
  
  .oi-rain:before {
    content:'\E0B1';
  }
  
  .oi-random:before {
    content:'\E0B2';
  }
  
  .oi-reload:before {
    content:'\E0B3';
  }
  
  .oi-resize-both:before {
    content:'\E0B4';
  }
  
  .oi-resize-height:before {
    content:'\E0B5';
  }
  
  .oi-resize-width:before {
    content:'\E0B6';
  }
  
  .oi-rss-alt:before {
    content:'\E0B7';
  }
  
  .oi-rss:before {
    content:'\E0B8';
  }
  
  .oi-script:before {
    content:'\E0B9';
  }
  
  .oi-share-boxed:before {
    content:'\E0BA';
  }
  
  .oi-share:before {
    content:'\E0BB';
  }
  
  .oi-shield:before {
    content:'\E0BC';
  }
  
  .oi-signal:before {
    content:'\E0BD';
  }
  
  .oi-signpost:before {
    content:'\E0BE';
  }
  
  .oi-sort-ascending:before {
    content:'\E0BF';
  }
  
  .oi-sort-descending:before {
    content:'\E0C0';
  }
  
  .oi-spreadsheet:before {
    content:'\E0C1';
  }
  
  .oi-star:before {
    content:'\E0C2';
  }
  
  .oi-sun:before {
    content:'\E0C3';
  }
  
  .oi-tablet:before {
    content:'\E0C4';
  }
  
  .oi-tag:before {
    content:'\E0C5';
  }
  
  .oi-tags:before {
    content:'\E0C6';
  }
  
  .oi-target:before {
    content:'\E0C7';
  }
  
  .oi-task:before {
    content:'\E0C8';
  }
  
  .oi-terminal:before {
    content:'\E0C9';
  }
  
  .oi-text:before {
    content:'\E0CA';
  }
  
  .oi-thumb-down:before {
    content:'\E0CB';
  }
  
  .oi-thumb-up:before {
    content:'\E0CC';
  }
  
  .oi-timer:before {
    content:'\E0CD';
  }
  
  .oi-transfer:before {
    content:'\E0CE';
  }
  
  .oi-trash:before {
    content:'\E0CF';
  }
  
  .oi-underline:before {
    content:'\E0D0';
  }
  
  .oi-vertical-align-bottom:before {
    content:'\E0D1';
  }
  
  .oi-vertical-align-center:before {
    content:'\E0D2';
  }
  
  .oi-vertical-align-top:before {
    content:'\E0D3';
  }
  
  .oi-video:before {
    content:'\E0D4';
  }
  
  .oi-volume-high:before {
    content:'\E0D5';
  }
  
  .oi-volume-low:before {
    content:'\E0D6';
  }
  
  .oi-volume-off:before {
    content:'\E0D7';
  }
  
  .oi-warning:before {
    content:'\E0D8';
  }
  
  .oi-wifi:before {
    content:'\E0D9';
  }
  
  .oi-wrench:before {
    content:'\E0DA';
  }
  
  .oi-x:before {
    content:'\E0DB';
  }
  
  .oi-yen:before {
    content:'\E0DC';
  }
  
  .oi-zoom-in:before {
    content:'\E0DD';
  }
  
  .oi-zoom-out:before {
    content:'\E0DE';
  }
