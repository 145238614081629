.App {
  text-align: center;
}
.a{
  color:#ffffff
}
.App-logo {
  align-self: center;
  float: top;
  position:absolute;
}
.lilTable{
  width:50%;
}

.lilerTable{
  width:40%;
  
}
.inline{
  display: inline-block;
}
.minTable{
  position: static;
  margin: 0px;
  padding: 0px;
  width:43px;
  column-width: 43px;
  text-align:left;
  align-content:left;
  -moz-transform: translate(-10px, 0px);
  -webkit-transform: translate(-10px, 0px);
  -o-transform: translate(-10px, 0px);
  -ms-transform: translate(-10px, 0px);
  transform: translate(-10px, 0px);
}
.cell{
  margin-left: 0px;
  padding-left: 0px;
  border-spacing: 0px
}
.profpic{
  margin: 0px;
  border-width: 1px;
  border-color: #000000;
  box-shadow: #000000
}
.banner{
  background-color:#30333a
}
.bigTable{
  width:300px;
  margin: 0px;
  padding: 0px;
  padding-block-end: 0px;
  position:relative;
}
.alignleft{
  text-align: left;
}
.alignright{
  text-align: right;
 
}
.App-header {
  float:top;
  width:60%;
  position:absolute;
  margin-top: 0;
  background-color: #282c34;
  min-height: 100vh;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 100px;
}
.Nav{
  border: 1px solid #000000;
  color:#00ffac;
  margin-top:150px;
  border-width:1px;
  border-radius: 3px;
  border-color:#000;
  height: 500px;
  float:left;
  width:15%;
  text-align:center;
}

.Navright{
  border: 1px solid #000000;
  color:#00ffac;
  border-width:1px;
  border-radius: 3px;
  border-color:#000;
  height: 500px;
  right:0px;
  float: left;
  width:15%;
  margin-top:150px;
  text-align:center;
}
.Main {
  margin-top:150px;
  float:left;
  width:60%;
  height:500px;
}
.App-link {
  color: #61dafb;
}

.thumb{
  padding: 5px;
  border: solid;  
  border-width: 2px;
  border-color:#000000;
}

.mainthumb{
  padding: 8px;
  border: solid;
  border-width: 2px;
  border-color:#a7a7a7;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.swingintopfwd {
	-webkit-animation: swing-in-top-fwd 0.75s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-top-fwd 0.75s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2019-1-10 22:45:57
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}

.link{
  text-decoration: underline;
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}
